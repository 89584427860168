import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import md5Libs from '@/utils/md5'
import guid from '@/utils/guid'
import queryParams from '@/utils/queryParams'
Vue.config.productionTip = false
Vue.prototype.$apiUrl = process.env.VUE_APP_BASE_API
Vue.prototype.$apiKey = process.env.VUE_APP_API_KEY

// 生成签名
Vue.prototype.$genSign = function(params) {
	// 32位随机字符串
	params.nonce = guid()
	// 当前时间戳
	params.timeStamp = Date.parse(new Date())
		.toString()
		.substr(0, 10)
	// 参数重新排序，去掉空参数
	var paramsArr = []
	for (var key in params) {
		if (params[key]) {
			paramsArr.push(key)
		}
	}
	// 参数按照字母先后顺序排序
	paramsArr = paramsArr.sort()
	// 拼接新参数对象
	var paramsNew = {}
	for (var i in paramsArr) {
		var pKey = paramsArr[i]
		paramsNew[pKey] = params[pKey]
	}
	// 对象转为URL参数格式的字符串
	var paramsStr = queryParams(paramsNew, false)
	// 字符串后加入apiSecret
	paramsStr = paramsStr + '&key=' + this.$apiKey
	// 生成md5签名
	var sign = md5Libs.md5(paramsStr)
	params.sign = sign
	// 返回新参数对象
	return params
}

// 拼接apiUrl
Vue.prototype.$apiUrlFull = function(apiAction, options) {
	var that = this
	const token = store.getters.getToken // token
	const adminId = store.getters.getId // token
	var params = {
		adminId: adminId || '',
		token: token // token
	}
	params = Object.assign(params, options)
	// 生成签名
	params = that.$genSign(params)
	// 参数对象转为url参数格式
	var paramsStr = queryParams(params)
	// 拼接url
	var apiUrl = that.$apiUrl + apiAction + paramsStr
	return apiUrl
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
