<template>
	<div class="bg-333333">
		<div class="container-xl pt-5 pb-4 text-D7D7D7">
			<div class="row row-between">
				<div class=" flex">
					<!-- <div class="flex-1">
						<div class="text-white text-18 pb-2">关于我们</div>
						<div class="mt-3 text-14 d-block text-D7D7D7 ">公司介绍</div>
						<div class="mt-3 text-14 d-block text-D7D7D7 ">用户协议</div>
						<div class="mt-3 text-14 d-block text-D7D7D7 ">政策隐私</div>
					</div> -->
					<div class="flex-1">
						<div class="text-white text-18 pb-2">产品与服务</div>
						<div class="mt-3 text-14 d-block text-D7D7D7" @click="joblist">找零工</div>
						<div class="mt-3 text-14 d-block text-D7D7D7 " @click="toSeekinglist">找人才</div>
						<div class="mt-3 text-14 d-block text-D7D7D7 " @click="toNoticelist">职场资讯</div>
					</div>
				</div>
				<div class="col-3 flex text-center">
					<div class="flex-1"><img src="http://cdn.tllgsc.com/img/qrcode_gzh.jpg" width="120" height="120"
							class="rounded">
						<div class="text-white text-16 pt-2">公众号</div>
					</div>
					<div class="flex-1"><img src="http://cdn.tllgsc.com/img/qrcode_xcx.jpg" width="120" height="120"
							class="rounded">
						<div class="text-white text-16 pt-2">小程序</div>
					</div>
				</div>
				<div class="">
					<div class="text-white text-18 pb-2">服务信息</div>
					<div class="mt-3 text-14">联系电话：0355-7588088</div>
					<!-- <div class="mt-3 text-14">服务时间：工作日8:00 - 18:00</div> -->
					<div class="mt-3 text-14">地址：屯留区零工市场二楼（长兴客运站斜对面）</div>
				</div>
			</div>
			<div class="pt-5 text-center">
				<div class="text-14">本网站之所有招聘信息、作品、版权均归屯留零工市场所有，未经书面授权不得转载。</div>
				<div class="text-14 pt-2 flex flex-center">
					<div class="mx-1">工信部备案号：<a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index"
							class="text-D7D7D7">晋ICP备2023025578号-3</a></div>
					<!-- <div class="mx-1">人力资源服务许可证：440902000009号</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {

			}
		},
		methods: {
			joblist() {
				if (this.$route.path == '/joblist') {
					return false
				}
				this.$router.push({
					path: '/joblist'
				})
			},
			toSeekinglist() {
				if (this.$route.path == '/seekinglist') {
					return false
				}
				this.$router.push({
					path: '/seekinglist'
				})
			},
			toNoticelist() {
				if (this.$route.path == '/noticelist') {
					return false
				}
				this.$router.push({
					path: '/noticelist'
				})
			}
		}
	}
</script>

<style>
	.d-block {
		cursor: pointer;
	}
</style>