<template>
	<el-dialog :visible.sync="show" width="1000px" :show-close="false" :before-close="close" :lock-scroll="false">
		<div class="">
			<div class="flex flex-x-end close-btn" @click="show = false">
				点击这里关闭
			</div>
			<div class="flex">
				<div class="code-image flex-wrap">
					<div class="code-image-box">
						<img src="http://cdn.tllgsc.com/img/qrcode_xcx.jpg" alt="" />
					</div>
					<div class="" style="margin-top: 10px;">微信扫一扫，打开小程序</div>
				</div>
				<div class="code-image flex-wrap" v-if="wx_qr_info && wx_qr_info.wx_qr_img1">
					<div class="code-image-box">
						<img :src="wx_qr_info.wx_qr_img1" alt="" />
					</div>
					<div class="" style="margin-top: 10px;">微信扫一扫，添加客服</div>
				</div>
				<div class="code-image flex-wrap" v-if="wx_qr_info && wx_qr_info.wx_qr_img2">
					<div class="code-image-box">
						<img :src="wx_qr_info.wx_qr_img2" alt="" />
					</div>
					<div class="" style="margin-top: 10px;">微信扫一扫，添加群</div>
				</div>
			</div>
		</div>
	</el-dialog>
</template>

<script>
	import {
		index_wx_qr
	} from '@/api/index.js'
	export default {
		name: 'popup-view',
		data() {
			return {
				show: false,
				wx_qr_info: {}
			}
		},
		methods: {
			getData() {
				index_wx_qr().then(res => {
					if (res.code == 0) {
						this.wx_qr_info = res.data.wx_qr_info
					}
				})
			},
			close() {
				this.show = false
			},
			open() {
				this.getData()
				this.show = true
			},
		}
	}
</script>

<style scoped lang="scss">
	::v-deep .el-dialog {
		border-radius: 6px;

		.el-dialog__header {
			display: none;
		}

		.el-dialog__body {
			padding: 10px;
			border: 5px solid skyblue;
			border-radius: 6px;
		}
	}

	.code-image {
		flex: 1;
		padding: 10px;
		align-items: center;
		justify-content: space-between;
		box-sizing: border-box;
		text-align: center;
		max-height: 600px;
		padding-bottom: 0;

		.code-image-box {
			width: 100%;
			height: calc(100% - 30px);

			display: flex;
			align-items: center;
			justify-content: center;
		}

		img {
			display: block;
			max-width: 100%;
			max-height: 100%;
		}
	}

	.close-btn {
		cursor: pointer;
	}
</style>