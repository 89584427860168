import request from '@/utils/request'

// 登录
export function index(params) {
	return request({
		url: 'index',
		method: 'post',
		data: params
	})
}

// 登录图片信息
export function index_wx_qr(params) {
	return request({
		url: 'index_wx_qr',
		method: 'post',
		data: params
	})
}

export function dial(params) {
	return request({
		url: 'dial',
		method: 'post',
		data: params
	})
}