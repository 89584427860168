import axios from 'axios'
import {
	Message
} from 'element-ui'
import Vue from 'vue'

const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API,
	timeout: 1000
})

service.interceptors.request.use(
	config => {
		config.url = Vue.prototype.$apiUrlFull(config.url)
		return config
	},
	error => {
		return Promise.reject(error)
	}
)

service.interceptors.response.use(
	response => {
		const res = response.data
		if (res.code !== 0) {
			if (res.code > 0) {
				Message({
					message: res.msg || 'Error',
					type: 'error',
					duration: 5 * 1000
				})
			}
			return res
		} else {
			return res
		}
	},
	error => {
		return Promise.reject(error)
	}
)

export default service