import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'home',
		component: Home
	},
	{
		path: '/joblist',
		name: 'joblist',
		component: () => import( /* webpackChunkName: "about" */ '../views/joblist.vue')
	}, {
		path: '/seekinglist',
		name: 'seekinglist',
		component: () => import( /* webpackChunkName: "about" */ '../views/seekinglist.vue')
	}, {
		path: '/detail',
		name: 'detail',
		component: () => import( /* webpackChunkName: "about" */ '../views/detail.vue')
	}, {
		path: '/seekingdetail',
		name: 'seekingdetail',
		component: () => import( /* webpackChunkName: "about" */ '../views/seekingdetail.vue')
	}, {
		path: '/noticelist',
		name: 'noticelist',
		component: () => import( /* webpackChunkName: "about" */ '../views/noticelist.vue')
	}, {
		path: '/noticedetail',
		name: 'noticedetail',
		component: () => import( /* webpackChunkName: "about" */ '../views/noticedetail.vue')
	}, {
		path: '/dial',
		name: 'Dial',
		component: () => import( /* webpackChunkName: "about" */ '../views/dial.vue')
	}

]

const router = new VueRouter({
	mode: 'history',
	routes
})

export default router