<template>
	<div class="header-box ">
		<div class="container-xl">
			<div class="header">
				<div class="nav-box container-xl">
					<div class="logo-box">
						<img src="@/assets/logo.jpeg" alt="" class="logo">
						<div class="logo-text">屯留零工市场</div>
					</div>
					<div class="nav-ul">
						<div class="nav-item" v-for="(item,index) in menuList"
							:class="current == item.name ? 'active' : ''" @click="menuClick(item)">
							{{item.title}}
						</div>
					</div>
					<div class="logo-box"></div>
				</div>
			</div>
		</div>
		<popupView ref="popup"></popupView>
	</div>
</template>

<script>
	import popupView from '@/components/popup-view'
	export default {
		components: {
			popupView
		},
		props: ['current'],
		data() {
			return {
				name: '', //账号名称
				menuList: [{
					name: 'index',
					title: '首页',
					path: '/'
				}, {
					name: 'linggong',
					title: '找零工',
					path: '/joblist'
				}, {
					name: 'rencai',
					title: '找人才',
					path: '/seekinglist'
				}, {
					name: 'zixun',
					title: '职场资讯',
					path: '/noticelist'
				}, {
					name: 'vip',
					title: '企业招募',
					path: '/qiye'
				}, ], //菜单数据
				activePath: ''
			}
		},
		created() {
			this.activePath = this.current
		},
		methods: {
			menuClick(item) {
				if (item.path == '/qiye') {
					this.$refs.popup.open()
				} else {
					if (this.activePath == item.name) {
						if (this.$route.path != item.path) {
							this.$router.push(item.path)
						}
						return false
					}
					this.activePath = item.name
					this.$router.push(item.path)
				}

			}
		},
		watch: {
			// $route:{
			// 	handler(val){
			// 		console.log(val)
			// 		console.log(1)
			// 	},
			// 	deep:true,
			// 	immediate:true
			// }
		}
	}
</script>

<style scoped lang="scss">
	.header-box {
		height: 57px;
		display: flex;
		align-items: center;
		background-color: #ffffff;

		.header {
			width: 100%;
			height: 57px;
			background-color: #ffffff;
			margin: 0 auto;
			position: fixed;
			top: 0;
			left: 0;
			z-index: 200;

			.nav-box {
				display: flex;
				align-items: center;

				.logo-box {
					width: 150px;
					// background-color: red;
					display: flex;
					align-items: center;

					.logo {
						display: block;
						width: 37px;
						max-height: 100%;
					}
					.logo-text{
						white-space: nowrap;
						font-size: 16px;
						margin-left: 10px;
						color: #4f4f4f;
						font-weight: bold;
					}
				}

				.nav-ul {
					display: flex;
					align-items: center;
					padding: 10px 40px;
					margin: 0 auto;

					.nav-item {
						position: relative;
						color: #4F4F4F;
						padding: 6px 30px 10px 30px;
						cursor: pointer;

					}

					.active {
						font-weight: bold;

						&::after {
							content: " ";
							position: absolute;
							left: 50%;
							transform: translate(-50%);
							bottom: 0px;
							width: 30px;
							height: 4px;
							background: #418CFF;
							border-radius: 2px;
						}
					}
				}
			}
		}
	}
</style>