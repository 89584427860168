<template>
	<div class="home">
		<headerView current="index"></headerView>
		<!-- 搜索框 -->
		<div class="search-box">
			<div class="container-xl flex flex-center">
				<div class="search-view flex flex-center">
					<div class="search-left flex-center">
						<el-dropdown trigger="click" @command="command">
							<span class="el-dropdown-link">
								{{command_current ? '找人才' : '找零工'}}<i class="el-icon-arrow-down el-icon--right"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item :command="0">找零工</el-dropdown-item>
								<el-dropdown-item :command="1">找人才</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
					<el-divider direction="vertical"></el-divider>
					<div class="search-right flex flex-center flex-1">
						<el-input placeholder="请输入内容" v-model="keyword" class="search-input flex-1"></el-input>
						<el-button type="primary" round class="search-button" size="small" icon="el-icon-search"
							@click="toSearch"></el-button>
					</div>
				</div>
			</div>
		</div>
		<!-- 菜单导航 -->
		<div class="container-xl">
			<div class="p-t-20">
				<div class="menu-box relative">
					<div class="menu-left">
						<div class="menu-list">
							<div class="menu-item flex row-between" v-for="(item , index) in cateGroup" :key="index"
								@click="menuClick(item)">
								<span class="menu-text">{{item.name}}</span>
								<i class="el-icon-caret-right"></i>
								<!-- <div class="cate-box">
									<div class="cate-item" v-for="(citem,cindex) in item.children" :key="cindex"
										@click="menuClick(citem)">
										{{citem.name}}
									</div>
								</div> -->
							</div>
						</div>
					</div>
					<div class="menu-right">
						<div class="flex menu-right-top">
							<div class="swiper">
								<el-carousel height="246px" class="0" v-if="activity_list && activity_list.length">
									<el-carousel-item v-for="(item,index) in activity_list" :key="index">
										<el-image :src="item.cover" fit="cover" class="code-item-img"></el-image>
									</el-carousel-item>
								</el-carousel>
								<el-carousel height="246px" class="1" v-else>
									<el-carousel-item>
										<div class="bg-box" @click="openCode">
											<img src="@/assets/active.png" fit="cover" class=""></img>
										</div>
									</el-carousel-item>
								</el-carousel>
							</div>
							<div class="join-box">
								<div class="jon-item" @click="openCode">
									<img src="@/assets/img1.png"></img>
								</div>
								<div class="jon-item" @click="openCode">
									<img src="@/assets/img2.png"></img>
								</div>
							</div>
						</div>
						<div class="code-img">
							<div class="code-item" @click="openCode">
								<el-image src="http://cdn.tllgsc.com/img/qrcode_gzh.jpg" fit="cover"
									class="code-item-img"></el-image>
								<div class="code-item-text">关注公众号</div>
							</div>
							<div class="code-item" @click="openCode">
								<el-image src="http://cdn.tllgsc.com/img/qrcode_xcx.jpg" fit="cover"
									class="code-item-img"></el-image>
								<div class="code-item-text">打开小程序</div>
							</div>
							<div class="code-item" @click="openCode" v-if="wx_qr_info && wx_qr_info.wx_qr_img1">
								<el-image :src="wx_qr_info.wx_qr_img1" fit="cover" class="code-item-img"></el-image>
								<div class="code-item-text">添加客服</div>
							</div>
							<div class="code-item" @click="openCode" v-if="wx_qr_info && wx_qr_info.wx_qr_img2">
								<el-image :src="wx_qr_info.wx_qr_img2" fit="cover" class="code-item-img"></el-image>
								<div class="code-item-text">添加群</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


		<div class="title container-xl">
			<i class="icon-1"></i>
			<div class="title-text">最新招工</div>
			<i class="icon-1 scaleX"></i>
		</div>

		<div class="container-xl ">
			<div class="list-box">
				<!-- <div class="list-cate-box-big">
					<div class="list-cate-box">
						<div class="list-cate-box-item " :class="cate_id == item.cate_id ? 'active' : ''"
							v-for="(item,index) in jobs" :key="index" @click="cateClick(item,index)">{{item.cate_name}}
						</div>
					</div>
				</div> -->
				<div class="list-cate-box-big">
					<el-tabs v-model="cate_id" type="card" class="list-cate-box" @tab-click="cateClick">
						<!-- <el-tab-pane class="list-cate-box-item" label="全部" name=""></el-tab-pane> -->
						<el-tab-pane class="list-cate-box-item" v-for="(item, index) in jobs" :key="item.id"
							:label="item.cate_name" :name="item.cate_id.toString()"></el-tab-pane>
					</el-tabs>
				</div>

				<div class="list-view"
					v-if="jobs && jobs.length && jobs[cate_index] && jobs[cate_index].works && jobs[cate_index].works.length">
					<div class="item-box" v-for="(item,index) in jobs[cate_index].works" :key="index"
						@click="detailClick(item)">
						<div class="item-view">
							<div class="flex row-between flex-y-end">
								<div class="item-name flex-1 text-ellipsis">{{item.title}}</div>
								<div class="item-price">
									{{item.salary1}}~{{item.salary2}}元/{{item.salary_type.split('')[1]}}
								</div>
							</div>
							<div class="item-address">{{item.address}}</div>
							<div class="item-cates flex">
								<div class="item-cate-item">{{item.salary_type}}</div>
							</div>
							<div class="flex row-between item-bottom">
								<div class="pub-name">{{item.contact_person}}</div>
								<div class="pub-time">{{item.publish_time_text}}日发布</div>
							</div>
						</div>
					</div>
				</div>
				<div class="text-center flex flex-center" style="height: 400px;" v-else>
					<div><img src="@/assets/icon-12.png" alt="">
						<div class="text-666666 text-18 pt-3">暂无数据</div>
					</div>
				</div>
				<div class="more-btn" @click.stop="toList1">更多招工</div>
			</div>
		</div>
		<div class="title container-xl">
			<i class="icon-1"></i>
			<div class="title-text">推荐人才</div>
			<i class="icon-1 scaleX"></i>
		</div>
		<div class="container-xl ">

			<div class="list-box">
				<div class="list-view new-list-view" v-if="seeking.length">
					<div class="item-box" v-for="(item,index) in seeking" :key="index" @click="toseekingDetail(item)">
						<div class="item-view">
							<div class="flex row-between">
								<div class="avatar-box">
									<el-image :src="item.user.avatar" style="width: 56px;height: 56px;" fit="cover"
										v-if="item.avatar"></el-image>
									<img src="@/assets/avatar.png" alt="" class="rounded-circle"
										style="width: 56px;height: 56px;border-radius: 50%;min-width: 56px;" v-else />
								</div>
								<div class="new-item-view-info">
									<div class="new-item-view-name">{{item.title}}</div>
									<div class="new-item-view-desc flex">
										<span v-if="item.age">{{item.age}}岁</span>
										<span v-if="item.sex"> · {{item.sex}}</span>
										<span v-if="item.educational"> · {{item.educational}}</span>
										<span v-if="item.experience"> · {{item.experience}}年工作经验</span>
									</div>
								</div>
							</div>
							<div class="p-t-20" v-if="item.salary">
								<div class="xinzi-box flex">
									<div>想拿：</div>
									<div class="pr-1 text-418CFF">{{item.salary_type}}/{{item.salary}}元</div>
									<div>的薪资</div>
								</div>
								<!-- <div class="address-box flex pt-2">
									<div>想在：</div>
									<div class="pr-1 text-418CFF">全国</div>
									<div>工作</div>
								</div> -->
							</div>
							<div class="flex row-between item-bottom">
								<div class="flex col-center">
									<i class="icon-2"></i>
									<div class="ml-1 text-666666 text-14" v-if="item.cate_name">{{item.cate_name}}</div>
									<div class="ml-1 text-666666 text-14" v-else>不限行业</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="text-center flex flex-center" style="height: 400px;" v-else>
					<div><img src="@/assets/icon-12.png" alt="">
						<div class="text-666666 text-18 pt-3">暂无数据</div>
					</div>
				</div>
			</div>
			<div class="more-btn mb-5" @click.stop="toList2">更多人才</div>
		</div>
		<popupView ref="popup"></popupView>
		<fotterView></fotterView>
	</div>
</template>

<script>
	import {
		index,
		index_wx_qr
	} from '@/api/index.js'
	import headerView from '@/components/layout/header-view'
	import fotterView from '@/components/layout/fotter-view'
	import popupView from '@/components/popup-view'

	export default {
		name: 'HomeView',
		components: {
			headerView,
			fotterView,
			popupView
		},
		data() {
			return {
				keyword: '',
				activity_list: [],
				cateGroup: [],
				jobs: [],
				seeking: [],
				cate_id: ' ',
				cate_index: 0,
				wx_qr_info: '',
				command_text: '',
				command_current: 0
			}
		},
		created() {
			this.getData()
		},
		methods: {
			menuClick(item) {
				this.$router.push({
					path: '/joblist',
					query: {
						cate_id: item.id,
					}
				})
			},
			toSearch() {
				if (this.command_current) {
					this.$router.push({
						path: '/seekinglist',
						query: {
							title: this.keyword
						}
					})
				} else {
					this.$router.push({
						path: '/joblist',
						query: {
							title: this.keyword
						}
					})
				}
			},
			command(e) {
				this.command_current = e
			},
			toList1() {
				this.$router.push({
					path: '/joblist'
				})
			},
			toList2() {
				this.$router.push({
					path: '/seekinglist'
				})
			},
			detailClick(item) {
				this.$router.push({
					path: '/detail',
					query: {
						id: item.id
					}
				})
			},
			toseekingDetail(item) {
				this.$router.push({
					path: '/seekingdetail',
					query: {
						id: item.id
					}
				})
			},
			openCode() {
				this.$refs.popup.open()
			},
			cateClick(tab, event) {
				// this.cate_id = item.cate_id
				this.cate_index = tab.index
			},
			getData() {
				index().then(res => {
					if (res.code == 0) {
						this.activity_list = res.data.activity_list
						this.cateGroup = res.data.cateGroup
						this.jobs = res.data.jobs
						this.seeking = res.data.seeking
						this.cate_id = this.jobs[0].cate_id.toString()
						this.wx_qr_info = res.data.wx_qr_info
					}
				})
			},
		}
	}
</script>
<style scoped lang="scss">
	.search-box {
		height: 146px;
		background-image: url('../assets/index-header-bg.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		display: flex;
		align-items: center;

		.search-view {
			background-color: #ffffff;
			border-radius: 100px;
			padding: 6px;
			width: 780px;

			.search-left {
				padding: 0 20px;
			}

			::v-deep .search-right {
				padding: 0;

				.search-input {
					.el-input__inner {
						border: none !important;
						line-height: 1;
						height: 35px;
						padding: 10px;
					}
				}

				.search-button {
					height: 35px;
					width: 100px;
					min-width: 100px;
					font-size: 20px;
					padding-top: 0;
					padding-bottom: 0;
				}
			}
		}
	}

	.menu-box {
		display: flex;
		position: relative;

		.menu-left {
			width: 230px;
			border-radius: 10px;
			overflow: hidden;

			.menu-list {
				width: 245px;
				height: 416px;
				background-color: #ffffff;
				padding: 16px 0;
				box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.01);
				border-radius: 10px;
				overflow-y: scroll;

				.menu-item {
					padding: 0 20px;
					height: 48px;
					line-height: 48px;
					cursor: pointer;

					.menu-text {
						font-size: 15px;
						color: #333333;
					}

					.cate-box {
						position: absolute;
						top: 0;
						right: 0;
						left: 230px;
						height: 0;
						bottom: 0;
						background-color: #fff;
						overflow-y: auto;
						z-index: 999;
						cursor: auto;
						width: 940px;
						line-height: 20px;
						height: 0;
						border-radius: 10px;

						.cate-item {
							display: inline-block;
							margin-right: 30px;
							color: #222222;
							margin-bottom: 20px;
							cursor: pointer;
							font-size: 14px;

							&:hover {
								color: #418CFF !important;
							}
						}
					}

					&:hover {
						box-shadow: 0 0 10px 5px #eee;
						border-radius: 5px;

						.cate-box {
							height: 416px;
							box-shadow: 0 0 10px 5px #eee;
							padding: 25px;
							border-radius: 10px;
						}
					}


				}
			}


		}

		.menu-right {
			height: 416px;
			flex: 1;

			.menu-right-top {
				height: calc(100% - 170px);
			}

			.swiper {
				flex: 1;
				background-color: #ffffff;
				border-radius: 10px;
				margin: 0 10px;
				box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.01);
				cursor: pointer;
				overflow: hidden;
			}

			.join-box {
				.jon-item {
					background-color: #ffffff;
					border-radius: 10px;
					width: 342px;
					height: 118px;
					box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.01);
					cursor: pointer;
					overflow: hidden;

					img {
						display: block;
						width: 100%;
						height: 100%;
						object-fit: cover;
					}

					&:not(:first-of-type) {
						margin-top: 10px;
					}
				}
			}

			.code-img {
				width: calc(100% - 10px);
				background-color: #ffffff;
				border-radius: 10px;
				margin-top: 10px;
				height: 160px;
				margin-left: 10px;
				box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.01);
				padding: 10px;
				display: flex;
				align-items: center;

				.code-item {
					height: 100%;
					width: 112px;
					margin-right: 10px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: space-between;
					cursor: pointer;

					.code-item-text {
						width: 100%;
						font-size: 12px;
						color: #666666;
						text-align: center;
						line-height: 20px;
					}

					.code-item-img {
						display: block;
						max-height: 100%;
						max-width: 100%;
						width: 100%;
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}

			}
		}
	}

	.title {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 40px;
		padding-bottom: 30px;

		.title-text {
			font-size: 32px;
			font-weight: bold;
			color: #4f4f4f;
			padding: 0 20px;
		}

		.icon-1 {
			background-image: url('../assets/icon-1.png');
			background-repeat: no-repeat;
			width: 105px;
			height: 11px;
			display: inline-block;
		}

		.scaleX {
			transform: scaleX(-1);
		}
	}

	.list-box {
		.list-cate-box-big {
			background-color: #fff;
			border-radius: 10px;
			box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.01);
			margin-bottom: 20px;
			height: 65px;
			overflow: hidden;
		}

		.list-cate-box {
			// display: flex;
			// align-items: center;
			// overflow-x: scroll;
			height: 100%;

			::v-deep .el-tabs__header {
				margin: 0;
				border: none;

				.el-tabs__nav {
					border: none;

					.el-tabs__item {
						white-space: nowrap;
						padding: 0 20px;
						cursor: pointer;
						height: 100%;
						box-sizing: border-box;
						line-height: 65px;
						border: none;
						font-size: 16px;
						position: relative;
					}

					.is-active {
						color: #418CFF !important;

						&::after {
							content: " ";
							position: absolute;
							left: 50%;
							transform: translate(-50%);
							bottom: 0px;
							width: 30px;
							height: 4px;
							background: #418CFF;
							border-radius: 2px;
						}
					}
				}

				.el-tabs__nav-next,
				.el-tabs__nav-prev {
					height: 65px;
					line-height: 65px;
				}
			}



			.list-cate-box-item {
				white-space: nowrap;
				padding: 20px;
				cursor: pointer;

				&:hover {
					color: #418CFF !important;
				}
			}

			.active {
				position: relative;
				color: #418CFF !important;

				&::after {
					content: " ";
					position: absolute;
					left: 50%;
					transform: translate(-50%);
					bottom: 0px;
					width: 30px;
					height: 4px;
					background: #418CFF;
					border-radius: 2px;
				}
			}
		}

		.list-view {
			margin-left: -10px;
			margin-right: -10px;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			.item-box {
				padding: 0 10px;
				border-radius: 10px;
				flex: 0 0 33.3333%;
				max-width: 33.3333%;
				box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.01);
				margin-bottom: 20px;
				cursor: pointer;

				.item-view {
					padding: 20px 30px;
					background-color: #ffffff;
					border-radius: 10px;
					overflow: hidden;

					.item-name {
						font-size: 18px;
						font-weight: bold;
						padding-right: 10px;
					}

					.item-price {
						font-size: 14px;
						color: #FF586C;
					}

					.item-address {
						padding-top: 20px;
						color: #999999;
						font-size: 14px;
					}

					.item-cates {
						padding-top: 20px;

						.item-cate-item {
							background: #EAF2FF;
							border-radius: 4px;
							font-size: 14px;
							font-weight: 400;
							color: #418CFF;
							padding: 5px 7px;
							margin-right: 10px;
						}
					}

					.item-bottom {
						margin-top: 20px;
						padding-top: 20px;
						border-top: 1px solid #F0F3F7 !important;

						.pub-name,
						.pub-time {
							font-size: 14px;
							color: #999999;
						}
					}

					&:hover {
						box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .11);

						.item-name {
							color: #418CFF;
						}
					}
				}


			}


		}

		.new-list-view {
			.item-view {
				&:hover {
					box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .11);

					.new-item-view-name {
						color: #418CFF;
					}
				}
			}

			.avatar-box {
				width: 56px;
				height: 56px;
				border-radius: 50%;
				overflow: hidden;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					display: block;
					width: 100%;
				}
			}

			.new-item-view-info {
				width: calc(100% - 56px);
				padding-left: 20px;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
			}

			.new-item-view-name {
				font-size: 18px;
				font-weight: bold;
				color: #333333;
			}

			.new-item-view-desc {
				width: 100%;
				padding-top: 10px;
				font-size: 14px;
				color: #999999;
			}

			.xinzi-box,
			.address-box {
				font-size: 16px;
				color: #4f4f4f;
			}

			.item-bottom {
				font-size: 14px;
				color: #666666;
			}

			.icon-2 {
				background-image: url('../assets/icon-2.png');
				background-repeat: no-repeat;
				width: 16px;
				height: 16px;
				display: inline-block;
				margin-right: 6px;
			}

		}

	}

	.bg-box {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			height: 100%;
			object-fit: cover;
		}
	}
</style>